import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import "./Tabs.library.scss"

import styles from "./Tabs.module.scss"
import GridContainer from "../layout/GridContainer/GridContainer"
import { FiChevronUp } from "react-icons/fi"
import ActivityBlockWrapper from "../ActivityBlockWrapper/ActivityBlockWrapper"
import WhatsOn from "../WhatsOn/WhatsOn"
import Insider from "../Insider/Insider"

const Tabs = props => {
  const [activeTab, setActiveTab] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (document.querySelector("." + styles.tabPaneActive)) {
      const tabPane = document.querySelector("." + styles.tabPaneActive)
      let tabHeight = parseInt(tabPane.getBoundingClientRect().height)
      if (activeTab === 1) {
        tabHeight = tabHeight + 20
      }
      setHeight(tabHeight + "px")
    } else {
      setHeight(0)
    }
  }, [activeTab])

  return (
    <GridContainer>
      <div className={styles.tabs}>
        <div className={styles.tabsOverrides + " tabs is-centered is-toggle"}>
          <ul className={styles.tabList}>
            <li className={activeTab === 1 ? styles.tabActive : ""}>
              <button
                onClick={() =>
                  activeTab === 1 ? setActiveTab(0) : setActiveTab(1)
                }
                aria-label="View Activity options"
              >
                Activities
              </button>
            </li>
            <li className={activeTab === 2 ? styles.tabActive : ""}>
              <button
                onClick={() =>
                  activeTab === 2 ? setActiveTab(0) : setActiveTab(2)
                }
                aria-label="View Whats on listing"
              >
                What's on
              </button>
            </li>
            <li className={activeTab === 3 ? styles.tabActive : ""}>
              <button
                onClick={() =>
                  activeTab === 3 ? setActiveTab(0) : setActiveTab(3)
                }
                aria-label="View Insider menu options"
              >
                Insider
              </button>
            </li>
          </ul>
        </div>

        <div
          className={styles.tabBody + " tabContent"}
          style={{ height: height }}
        >
          <div
            className={activeTab === 1 ? styles.tabPaneActive : styles.tabPane + ' ' + styles.tabPaneNoActive}
          >
            <ActivityBlockWrapper activities={props.adventureMenu}/>

            <button
              className={styles.closeButton + " button is-fullwidth buttonText"}
              onClick={() => setActiveTab(0)}
              aria-label="Close"
            >
              Close
              <span className={styles.closeIcon + " icon"}>
                <FiChevronUp />
              </span>
            </button>
          </div>
          <div
            className={activeTab === 2 ? styles.tabPaneActive : styles.tabPane + ' ' + styles.tabPaneNoActive}
          >
            <WhatsOn
              events={props.whatsOn.events}
              summary={true}
              message={props.whatsOn.message ? props.whatsOn.message : undefined}
            />

            <button
              className={styles.closeButton + " button is-fullwidth buttonText"}
              onClick={() => setActiveTab(0)}
              aria-label="Close"
            >
              Close
              <span className={styles.closeIcon + " icon"}>
                <FiChevronUp />
              </span>
            </button>
          </div>
          <div
            className={activeTab === 3 ? styles.tabPaneActive : styles.tabPane + ' ' + styles.tabPaneNoActive}
          >
            <Insider menu={props.menu} />

            <button
              className={styles.closeButton + " button is-fullwidth buttonText"}
              onClick={() => setActiveTab(0)}
              aria-label="Close"
            >
              Close
              <span className={styles.closeIcon + " icon"}>
                <FiChevronUp />
              </span>
            </button>
          </div>
        </div>
      </div>
    </GridContainer>
  )
}

Tabs.propTypes = {
  menu: PropTypes.array,
  adventureMenu: PropTypes.array,
  whatsOn: PropTypes.object,
}

Tabs.defaultProps = {
  menu: null,
  adventureMenu: null,
  whatsOn: null,
}

export default Tabs
