import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, useRef } from "react"
import EmblaCarouselReact from "embla-carousel-react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

import styles from "./Carousel.module.scss"

const Carousel = ({ children }) => {
  const options = {
    align: "center",
    containerSelector: "*",
    slidesToScroll: 1,
    containScroll: true,
    draggable: true,
    dragFree: false,
    loop: false,
    speed: 10,
    startIndex: 0,
    selectedClass: "is-selected",
    draggableClass: "is-draggable",
    draggingClass: "is-dragging",
  }

  const myOptions = {
    autoplay: true,
    delay: 15000,
  }

  const [embla, setEmbla] = useState(null)
  const [slide, setSlide] = useState(0)
  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(true)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const onScrollPrev = useCallback(() => embla.scrollPrev(), [embla])
  const onScrollNext = useCallback(() => embla.scrollNext(), [embla])
  const scrollTo = useCallback(index => embla.scrollTo(index), [embla])

  useInterval(
    () => {
      if (slide === scrollSnaps.length - 1) {
        scrollTo(0)
      } else {
        onScrollNext()
      }
    },
    myOptions.autoplay ? myOptions.delay : null
  )

  useEffect(() => {
    if (embla) {
      setScrollSnaps(embla.scrollSnapList())
      embla.on("select", () => {
        setSlide(embla.selectedScrollSnap())
        setCanScrollPrev(embla.canScrollPrev())
        setCanScrollNext(embla.canScrollNext())
      })
    }
  }, [embla])

  const renderPager = useCallback(() => {
    if (embla) {
      return embla
        .scrollSnapList()
        .map((snap, index) => (
          <button
            className={
              index === slide ? styles.pagerButtonActive : styles.pagerButton
            }
            key={index}
            active={index === slide ? 1 : 0}
            aria-label={`Scroll to slide number ${index + 1}`}
            type="button"
            onClick={() => embla.scrollTo(index)}
          />
        ))
    }
    return null
  }, [embla, slide])

  return (
    <div className={styles.wrapper}>
      <EmblaCarouselReact emblaRef={setEmbla} options={options}>
        <div className={styles.carousel}>
          {children}
        </div>
      </EmblaCarouselReact>

      <div className={styles.pager}>{renderPager()}</div>

      <button
        aria-label="Previous slide"
        onClick={onScrollPrev}
        disabled={!canScrollPrev}
        className={styles.previous}
      >
        <span className={styles.previousIcon + " icon"}>
          <FaChevronLeft />
        </span>
      </button>
      <button
        aria-label="Next slide"
        onClick={onScrollNext}
        disabled={!canScrollNext}
        className={styles.next}
      >
        <span className={styles.nextIcon + " icon"}>
          <FaChevronRight />
        </span>
      </button>
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default Carousel
