import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

// Import component specific styles.
import styles from "./Insider.module.scss"

const Insider = props => (
  <div className={styles.wrapper}>
    <ul>
      {props.menu.map(menuItems => {
        return (
          <li key={menuItems.destination.title}>
            <Link className={styles.link} to={"/" + menuItems.destination.slug} aria-label={'Navigate to ' + menuItems.destination.title}>
              <h4>{menuItems.destination.title}</h4>
            </Link>
          </li>
        )
      })}
    </ul>
  </div>
)

Insider.propTypes = {
  menu: PropTypes.array,
}

Insider.defaultProps = {
  menu: [],
}

export default Insider
