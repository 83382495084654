import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import ActivityBlock from "./ActivityBlock"

// Import necessary Bulma CSS Framework components.
import "./AcitvityBlockWrapper.library.scss"

// Import component specific styles.
import styles from "./ActivityBlockWrapper.module.scss"

const ActivityBlockWrapper = props => {
  return (
    <GridContainer>
      <div className={styles.activityBlockWrapper}>
        { props.activities.map((activity, index) => {
          return (
            <ActivityBlock
              key={activity.link.title + index}
              imageSrc={activity.image.fluid}
              imageAlt={activity.image.alt}
              imageTitle={activity.image.title}
              titleText={activity.linkTitle ? activity.linkTitle : activity.link.title}
              descriptionText={activity.activityDescription}
              ctaLink={activity.link.slug}
              iconLink={activity.link.icon !== null ? activity.link.icon.fixed : ''}
              iconAlt={activity.link.icon !== null  ? activity.link.icon.alt : ''}
            />
          )
        })}
      </div>
    </GridContainer>
  )
}

ActivityBlockWrapper.propTypes = {
  activities: PropTypes.array,
}

ActivityBlockWrapper.defaultProps = {
  activities: ``,
}

export default ActivityBlockWrapper
