import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { FaRegCalendarAlt } from "react-icons/fa"
import { FiChevronRight } from "react-icons/fi"
import ICalendarLink from "react-icalendar-link"
import Img from "gatsby-image"

// Import necessary Bulma CSS Framework components.
import "./WhatsOn.library.scss"

// Import component specific styles.
import styles from "./WhatsOn.module.scss"

const WhatsOn = props => {
  return (
    <div className={styles.wrapper}>
      {props.events === null || props.events.length === 0 ? (
        <h3 className={styles.notice}>{props.message}</h3>
      ) : (
        props.events.map((eventData, index, events) => {
          const convertTime = (calendarDate, time) => {
            const dateSplit = calendarDate.split("/")
            const timeSplit = time.split(":")
            const date = new Date(
              dateSplit[2],
              dateSplit[1] - 1,
              dateSplit[0],
              timeSplit[0],
              timeSplit[1]
            )
            return date
          }

          const { calendarDate, startTime, endTime } = eventData

          const event = {
            title: eventData.title,
            description: eventData.descriptionUsedForListings,
            startTime: convertTime(calendarDate, startTime),
            endTime: convertTime(calendarDate, endTime),
            location: eventData.locationInfo.replace(/,/g, " -"),
          }

          const removeSpaceFromTitle = title => {
            const splitTitle = title.split(" ")
            const joinTitle = splitTitle.join("")
            return joinTitle
          }

          const checkCalenderDate = () => {
            const splitDate = calendarDate.split("/")
            const month = splitDate[1]
            return month > 1 && month < 12 ? true : false
          }

          const isFutureDate = targetDate => {
            return Date.now() < targetDate
          }

          return (
            <React.Fragment key={index}>
              {/* If the current event date is different from the previous event date, print header */}
              {(index === 0 || eventData.date !== events[index - 1].date) && (
                <div className={styles.header}>
                  <div className="columns is-gapless is-mobile">
                    <div className="column">
                      <h2 className={styles.date}>{eventData.date}</h2>
                    </div>
                    <div className="column">
                      <h2 className={styles.day}>{eventData.dayOfWeek}</h2>
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.eventRow + " columns is-mobile"}>
                <div className="column is-hidden-mobile is-2-tablet">
                  <Link
                    to={`/${eventData.slug}`}
                    aria-label={"View " + eventData.title}
                    tabIndex="-1"
                  >
                    <button className={styles.buttonWrapper}>
                      <Img
                        className={styles.eventImage}
                        fixed={eventData.imageUsedForListings.fixed}
                        title={eventData.imageUsedForListings.title}
                        alt={eventData.imageUsedForListings.alt}
                      />
                    </button>
                  </Link>
                </div>
                <div className="column is-10-mobile is-3-tablet">
                  <div className={styles.eventHeader}>
                    <Link
                      to={`/${eventData.slug}`}
                      aria-label={"View " + eventData.title}
                      tabIndex="-1"
                    >
                      <button className={styles.buttonWrapper}>
                        <h3 className={styles.eventTitle}>{eventData.title}</h3>
                      </button>
                    </Link>
                    <p className={styles.eventTime}>
                      {eventData.startTime && eventData.endTime
                        ? `${eventData.startTime} - ${eventData.endTime}`
                        : "Timings to be Confirmed"}
                    </p>

                    {checkCalenderDate() && isFutureDate(event.endTime) && (
                      <div className={styles.calendarLink}>
                        {/* Necessary to prevent build issue due to 'window' reference in
                        'React iCalendar Link' plugin. */}
                        {typeof window !== "undefined" && (
                          <ICalendarLink
                            event={event}
                            filename={
                              removeSpaceFromTitle(eventData.title) + ".ics"
                            }
                          >
                            <span className={styles.calendarIcon + " icon"}>
                              <FaRegCalendarAlt />
                            </span>
                            <h4>Add to calendar</h4>
                          </ICalendarLink>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="column is-hidden-mobile">
                  <p className={styles.eventBody}>
                    {eventData.descriptionUsedForListings}
                  </p>
                </div>
                <div className="column is-2-mobile is-1-tablet">
                  <Link
                    tabindex="-1"
                    to={`/${eventData.slug}`}
                    aria-label={"View " + eventData.title}
                    className={styles.eventLink}
                  >
                    <button aria-label={eventData.title}>
                      <span className={styles.chevronIcon + " icon"}>
                        <FiChevronRight />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </React.Fragment>
          )
        })
      )}

      {props.summary && (
        <Link to="/whats-on" aria-label="Find out more from the Whats On page">
          <button
            aria-label="Find out more from the Whats On page"
            className={styles.seeMoreButton + " button"}
          >
            See more
          </button>
        </Link>
      )}
    </div>
  )
}

WhatsOn.propTypes = {
  events: PropTypes.array,
  summary: PropTypes.bool,
  message: PropTypes.string,
}

WhatsOn.defaultProps = {
  events: null,
  summary: false,
  message:
    "There are currently no upcoming events. Please check back soon for updates!",
}

export default WhatsOn
