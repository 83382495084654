import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"

import "./HeroBanner.library.scss"

import styles from "./HeroBanner.module.scss"


const HeroBannerImage = props => (
  <div className={styles.heroBannerImageWrapper}>
    <img
      alt={props.alt}
      src={props.src}
      srcSet={props.srcSet}
    />
    <div className={styles.captionWrapper}>
      <GridContainer>
        <div className={styles.caption}>{props.title}</div>
      </GridContainer>
    </div>
  </div>
)

HeroBannerImage.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
}

HeroBannerImage.defaultProps = {
  title: ``,
  alt: ``,
  src: ``,
  srcSet: ``,
}

export default HeroBannerImage
