import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import Tabs from "../Tabs/Tabs"
import Carousel from "../Carousel/Carousel"
import GridContainer from "../layout/GridContainer/GridContainer"
import HeroBannerImage from "./HeroBannerImage"

import "./HeroBanner.library.scss"

import styles from "./HeroBanner.module.scss"


const HeroBanner = props => (
  <>
    { props.isHomePage &&
      <Helmet>
        <body className= "home no-outline" />
      </Helmet>
    }

    <div className={styles.heroBannerWrapper}>
      <div
        className={
          props.primaryTheme && props.isHomePage ? styles.headerBar + ' ' + styles.hideMobile : styles.headerBarSecondary
        }
      >
        {props.title && <GridContainer><h1 className="beachesStyle">{props.title}</h1></GridContainer>}
      </div>
      {props.images && props.images.length > 1 &&
        <Carousel>
          {/*
            Carousel has issues accepting native Gatsby Image objects, so we'll
            build images with srcSet attribute instead.
          */}
          {props.images.map(headerImage => {
            return (
              <HeroBannerImage
                key={headerImage.title}
                title={headerImage.title}
                alt={headerImage.alt}
                src={headerImage.sources[0].src}
                srcSet={`
                  ${headerImage.sources[0].src} 768w,
                  ${headerImage.sources[1].src} 1023w,
                  ${headerImage.sources[2].src} 1215w,
                  ${headerImage.sources[3].src} 1920w
                `}
              />
            )
          })}
        </Carousel>
      }
      {props.images && props.images.length === 1 &&
        <HeroBannerImage
          key={props.images[0].title}
          title={props.images[0].title}
          alt={props.images[0].alt}
          src={props.images[0].sources[0].src}
          srcSet={`
            ${props.images[0].sources[0].src} 768w,
            ${props.images[0].sources[1].src} 1023w,
            ${props.images[0].sources[2].src} 1215w,
            ${props.images[0].sources[3].src} 1920w
          `}
        />
      }
      {props.tabs && <Tabs adventureMenu={props.adventureMenu} whatsOn={props.whatsOn} menu={props.insiderMenu} />}
    </div>
  </>
)

HeroBanner.propTypes = {
  adventureMenu: PropTypes.array,
  title: PropTypes.string,
  insiderMenu: PropTypes.array,
  tabs: PropTypes.bool,
  images: PropTypes.array,
  primaryTheme: PropTypes.bool,
  whatsOn: PropTypes.object,
  isHomePage: PropTypes.bool
}

HeroBanner.defaultProps = {
  title: "",
  insiderMenu: null,
  tabs: false,
  images: null,
  primaryTheme: false,
  whatsOn: null,
  isHomePage: false,
}

export default HeroBanner
