import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { FaChevronRight } from "react-icons/fa"
import Img from "gatsby-image"

// Import necessary Bulma CSS Framework components.
import "./AcitvityBlockWrapper.library.scss"

// Import component specific styles.
import styles from "./ActivityBlockWrapper.module.scss"

const ActivityBlock = props => (
  <div className={styles.activityBlock}>
    <Link
      to={`/${props.ctaLink}`}
      aria-label={'Learn more about ' + props.titleText + ' at Selsey'}
    >
      <div className={styles.imageWrapper}>
        <Img fluid={props.imageSrc} alt={props.imageAlt} title={props.imageTitle} className={styles.blockBackgroundImage}/>
      </div>

      <div className={styles.textWrapper}>
        <p className={styles.imageText}>
          {props.titleText}
          { props.iconLink && props.iconAlt && (
            <span className={styles.activityIcon}>
              <Img fixed={props.iconLink} title={props.imageTitle} alt={props.iconAlt}/>
            </span>
          )}
        </p>
        <p className={styles.activityDescriptionText}>{props.descriptionText}</p>
        <div className={styles.cta}>
          {props.titleText} <FaChevronRight className={styles.descriptionIcon}/>
        </div>
      </div>
    </Link>
  </div>
)

ActivityBlock.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imageTitle: PropTypes.string,
  titleText: PropTypes.string,
  descriptionText: PropTypes.string,
  ctaLink: PropTypes.string,
  iconLink: PropTypes.string,
  iconAlt: PropTypes.string,
}

ActivityBlock.defaultProps = {
  imageSrc: ``,
  imageAlt: ``,
  imageTitle: ``,
  titleText: ``,
  ctaLink: ``,
  iconLink: ``,
  iconAlt: ``,
}

export default ActivityBlock
